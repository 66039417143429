<p-dialog [dismissableMask]="true" [modal]="true" [header]="headerLabel | translate" [(visible)]="showAddClient"
  styleClass="add-foodextra-dialog form-container filter-container" [closable]="true" [draggable]="false"
  blockScroll="true" [resizable]="true" [focusOnShow]="false" (onHide)="onCancelClick()">

  <div class="form-item">
    <div class="dialog-outer-container">
      <div class="page-body">
        <form [formGroup]="pageForm">
          <div class="form-container-column grid col-12 lg:col-12 md:col-0 p-0">

            <div class="form-item col-12 lg:col-12 md:col-12">
              <div class="form-field">
                <div class="menu-image-div">
                  <img [src]="localImgUrl" class="menu-image-list" />
                  <span *ngIf="!checkDisable()" class="fa menu-edit-icon edit-icon-margin"
                    (click)="onEditImageClick()"></span>
                  <div class="form-errors menu-image-list"
                    *ngIf="pageForm.controls.image.errors  && (pageForm.controls.image.dirty || pageForm.controls.image.touched || submitted)">
                    <div [hidden]="!pageForm.controls.image.errors.required" translate>
                      msg_select_image
                    </div>
                  </div>
                </div>
                <div class="brand-wrapper">
                  <div class="form-label">
                    <label for="brandName" translate>label_brandName</label>
                  </div>
                  <div class="form-control">
                    <input formControlName="brandName" id="brandName" class="full-width" type="text" appNoWhiteSpace
                      pInputText disabled/>
                  </div>
                </div>
                <div class="form-label">
                  <label for="itemName" translate>label_item<span class="required-star">*</span></label>
                </div>
                <div class="form-control">
                  <input formControlName="itemName" id="itemName" class="full-width" type="text" appNoWhiteSpace
                    pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
                </div>
                <div class="form-errors"
                  *ngIf="pageForm.controls.itemName.errors  && (pageForm.controls.itemName.dirty || pageForm.controls.itemName.touched || submitted)">
                  <div [hidden]="!pageForm.controls.itemName.errors.required" translate>
                    field_is_required
                  </div>
                  <div [hidden]="!pageForm.controls.itemName.errors.minlength" translate>
                    min_length_1char
                  </div>
                  <div [hidden]="!pageForm.controls.itemName.errors.maxlength" translate>
                    max_length_50char
                  </div>
                </div>
              </div>
            </div>

            <div class="form-item col-6 lg:col-6 md:col-5">
              <div class="form-field">
                <div class="form-label">
                  <label for="sellingPrice" translate>label_selling_price<span class="required-star">*</span></label>
                </div>
                <div class="form-control selling-price-wrapper">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">$</span>
                    <input formControlName="sellingPrice" id="sellingPrice" class="full-width" type="text"
                      inputmode="numeric" appTwoDecimal appNoWhiteSpace pInputText />
                  </div>
                </div>
                <div class="form-errors"
                  *ngIf="pageForm.controls.sellingPrice.errors  && (pageForm.controls.sellingPrice.dirty || pageForm.controls.sellingPrice.touched || submitted)">
                  <div [hidden]="!pageForm.controls.sellingPrice.errors.required" translate>
                    field_is_required
                  </div>
                  <div [hidden]="!pageForm.controls.sellingPrice.errors.minlength" translate>
                    Minimum 3 characters required.
                  </div>
                  <div [hidden]="!pageForm.controls.sellingPrice.errors.maxlength" translate>
                    Maximum 9 digits allowed.
                  </div>
                </div>
              </div>
            </div>


            <div class="form-item col-6 lg:col-6 md:col-5">
              <div class="form-field">
                <div class="form-label">
                  <label for="selection" translate>label_selection<span class="required-star">*</span></label>
                </div>
                <div class="form-control">
                  <p-dropdown formControlName="selection" appendTo="body" [style]="{ 'height': '40px', 'padding' : '0'}"
                    [options]="yesNoOptions" class="full-width" (onChange)="onChangeSelection()"></p-dropdown>
                  <!-- <p-dropdown formControlName="selection" [options]="selectionOptions"></p-dropdown> -->
                </div>
                <div class="form-errors"
                  *ngIf="pageForm.controls.selection.errors  && (pageForm.controls.selection.dirty || pageForm.controls.selection.touched || submitted)">
                  <div [hidden]="!pageForm.controls.selection.errors.required" translate>
                    field_is_required
                  </div>
                </div>
              </div>
            </div>

            <div class="form-item col-6 lg:col-6 md:col-5" *ngIf="pageForm.controls.selection.value === 'multiple'">
              <div class="form-field">
                <div class="form-label">
                  <label for="minimumQuantity" translate>label_min_quentity<span class="required-star">*</span></label>
                </div>
                <div class="form-control">
                  <input formControlName="minimumQuantity" id="minimumQuantity" class="full-width" type="text"
                    (keyup)="checkMinMaxValueValid('minQuentity')" inputmode="numeric" appTwoDecimal appNoWhiteSpace
                    pInputText />
                </div>
                <div class="form-errors"
                  *ngIf="pageForm.controls.minimumQuantity.errors  && (pageForm.controls.minimumQuantity.dirty || pageForm.controls.minimumQuantity.touched || submitted)">
                  <div [hidden]="!pageForm.controls.minimumQuantity.errors.required" translate>
                    field_is_required
                  </div>
                  <div [hidden]="!pageForm.controls.minimumQuantity.errors.maxlength" translate>
                    Maximum 4 digits allowed.
                  </div>
                </div>
                <div class="form-errors" *ngIf="!validMinimumQuantity && !pageForm.controls.minimumQuantity.errors"
                  translate>
                  Invalid minimum quantity
                </div>
              </div>
            </div>

            <div class="form-item col-6 lg:col-6 md:col-5" *ngIf="pageForm.controls.selection.value === 'multiple'">
              <div class="form-field">
                <div class="form-label">
                  <label for="maximumQuantity" translate>label_max_quentity<span class="required-star">*</span></label>
                </div>
                <div class="form-control">
                  <input formControlName="maximumQuantity" id="maximumQuantity" class="full-width" type="text"
                    (keyup)="checkMinMaxValueValid('maxQuentity')" inputmode="numeric" appTwoDecimal appNoWhiteSpace
                    pInputText />
                </div>
                <div class="form-errors"
                  *ngIf="pageForm.controls.maximumQuantity.errors  && (pageForm.controls.maximumQuantity.dirty || pageForm.controls.maximumQuantity.touched || submitted)">
                  <div [hidden]="!pageForm.controls.maximumQuantity.errors.required" translate>
                    field_is_required
                  </div>
                  <div [hidden]="!pageForm.controls.maximumQuantity.errors.maxlength" translate>
                    Maximum 4 digits allowed.
                  </div>
                </div>
                <div class="form-errors" *ngIf="!validMaximumQuantity && !pageForm.controls.maximumQuantity.errors"
                  translate>
                  Invalid maximum quantity
                </div>
              </div>
            </div>

            <div class="col-12 p-justify-end page-buttons p-mt-2 p-mr-2 p-mb-2 save-button">
              <button pButton [label]="'btn_cancel' | translate" type="submit" class="cancel-btn"
                (click)="onCancelClick()"></button>
              <button pButton [label]="'btn_save' | translate" type="submit" class="btn-save bottom-align"
                (click)="onSave()"></button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>


  <p-dialog [dismissableMask]="false" [modal]="true" [header]="'label_select_image' | translate" modal="true"
    position="center" autoResize="true"  [draggable]="false" styleClass="menu-image extra-menusection"
    [(visible)]="showImageListPopUp" [focusOnShow]="false" *ngIf="showImageListPopUp">

    <div class="col-12 menu-content" *ngIf = "brandNameOptions.length > 0">
      <!-- <form [formGroup]="imagePageForm"> -->
        <div class="grid">
          <div class="col-6 lg:col-6 pad-l-0">
            <div class="form-field">
              <div class="form-label">
                <label for="menuBrand" translate>label_brandName<span class="required-star">*</span></label>
              </div>
              <div class="form-control">
                <p-dropdown [(ngModel)]="menuBrand" (onChange)="onChangeBrand($event)" [disabled]="checkForDisable()"
                  [options]="brandNameOptions">
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-6 lg:col-6 pad-r-0">
            <div class="form-field form-control">
              <label for="searchTextl" translate><br> </label>
              <input class="full-width"  (keyup)="onSearchImages($event)"
                type="text" [placeholder]="'label_search_image_text'|translate" pInputText appPreventSpecialCharacters
                pTooltip="{{'label_search_name' | translate}}" [(ngModel)]="searchText" />
            </div>
          </div>
        </div>
      <!-- </form> -->
      <div class="grid images-content p-mt-2" *ngIf="localMenuImageList && localMenuImageList.length > 0">
        <div class="col-12 lg:col-4 md:col-4 align-center" *ngFor="let eachImage of localMenuImageList; let i = index;">
          <div class="col-12 label-img-div">
            <div class="fix-div-width-height">
              <label for="data{{i}}">
                <img [src]="eachImage.signUrl" class="img-class" />
              </label>
            </div>
          </div>
          <div class="col-12 label-wrapper">
            <input type="radio" id="menu" [(ngModel)]="selectedImageIndex" name="passengerAppApplicable"
              value="{{eachImage['id']}}" id="data{{i}}" />
            <label class="mar-t-b-0 filename" for="data{{i}}">{{eachImage.originalFileName}}</label>
          </div>
        </div>
        <div class="col-12 lg:col-8 md:col-8 error-align" *ngIf="localMenuImageList.length == 0" translate>
          label_no_records_found

        </div>
      </div>
    </div>
    <p-footer>
      <div class="image-selection-popup btn">

        <button appAccessProvider pButton class="small-btn" appRipple type="button" label="Save"
          (click)="onSelectImage()"></button>
        <button class="small-btn cancel-btn" pButton appRipple type="button" label="Cancel"
          (click)="onCancelPopUp()"></button>
      </div>
    </p-footer>
  </p-dialog>