<div class="container">
    <!-- <div class="graph-title">
        <h1>Sales on Week Day</h1>
    </div> -->
    <div class="graph-legend">
        <div class="category">Event Sales</div>
        <div class="data-value">
            <div id="current-value" class="current">
                $200
            </div>
            <div id="compared-value" class="compared">
                US$180
            </div>
        </div>
        <div id="timeline" class="timeline">Sunday</div>
    </div>
    <div class="graph-container">
        <canvas #barCanvas style="height:15vh; width:10vw"></canvas>
    </div>
</div>