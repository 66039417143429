import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ErrorBindingService } from '../../services/error-binding.service';
import { UiService } from '../../services/ui.service';
import { StorageService } from '../../services/storage.service';
import { AdminService } from '../../../admin/services/admin.service';

@Component({
  selector: 'app-editable-food-extra',
  templateUrl: './editable-food-extra.component.html',
  styleUrls: ['./editable-food-extra.component.scss']
})
export class EditableFoodExtraComponent implements OnInit {
  @Input() itemDetails: any;
  @Input() isAddItem;
  @Output() reloadMenuList: EventEmitter<any> = new EventEmitter();
  @Output() onCancelAddNew: EventEmitter<any> = new EventEmitter();

  pageForm: FormGroup;
  imagePageForm: FormGroup;
  showAddClient = false;
  submitted = false;
  yesNoOptions = AppSettings.ADD_FOOD_EXTRA_ITEM_SLECTION_OPTIONS;
  localImgUrl = AppSettings.DEFAULT_NO_IMAGE;

  showImageListPopUp = false;
  newMenuSelectedImageFileId;
  isImageSelected = true;
  menuImageList = this.franchiseService.foodExtraItemsImageList;
  selectedImageIndex;
  selectedImageObject;

  itemName;
  itemDescription;
  itemPrice;

  ID;
  eventDetails;
  validMinimumQuantity = true;
  validMaximumQuantity = true;
  validMinimumQuantityInRange = true;
  validMaximumQuantityInRange = true;
  parentType = 'single';
  minQtyAllowed;
  maxQtyAllowed;
  localMenuImageList: any[];
  searchText = '';
  valid = true;
  headerLabel = this.commonService.getLabel('label_food_extra_add_item');
  brandNameOptions: any = [];
  loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA)).franchiseId;
  defaultBrand: any;
  menuBrand: any;
  menuItemsImageList = [];
  selectedBrandObject: any;
  loginUserRole;
  // @ViewChild('fondovalor') fondovalor:ElementRef;

  constructor(private formBuilder: FormBuilder,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private adminService: AdminService,
    private storageService: StorageService) {
  }

  ngOnInit(): void {
    this.loginUserRole = this.commonService.getLoginRole();
    console.log('loginUserRole', this.loginUserRole);
    this.initForm();
    this.showAddClient = true;
    this.menuImageList = this.franchiseService.foodExtraItemsImageList;
  }

  getBrandList(id, isNew?) {
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
      this.franchiseService.getBrandList(id, isNew).subscribe((result) => {
        this.brandNameOptions = result.map(element => ({
          label: element.brandName, value: element.brandId
        }));
        this.checkAndSetCommonVars();
      });
    } else {
      this.adminService.getAllBrandsList().subscribe((result) => {
        this.brandNameOptions = result.map(element => ({
          label: element.brandName,
          value: element.id
        }));
        this.checkAndSetCommonVars();
      });
    }
  }

  checkAndSetCommonVars() {
    const selectedMenuBrand = this.pageForm.controls.brandId.value ? this.pageForm.controls.brandId.value : '';
    const selectedMenuBrandObject = selectedMenuBrand ? this.brandNameOptions.filter(eb => eb.value == selectedMenuBrand)[0] : '';
    this.selectedBrandObject = selectedMenuBrandObject ? selectedMenuBrandObject : this.brandNameOptions[0];
    this.menuBrand = selectedMenuBrand ? selectedMenuBrand : this.selectedBrandObject.value;
    this.searchText = '';
    this.loadImagesOnSelectedbrand();
  }

  loadImagesOnSelectedbrand() {
    this.localMenuImageList = this.franchiseService.foodExtraItemsImageList.filter((item) => {
      return item.brandId == this.selectedBrandObject.value;
    });
    this.menuImageList = this.localMenuImageList;
    this.menuItemsImageList = this.localMenuImageList;
    this.setImagePageForm(this.menuBrand, this.searchText);
  }

  // setImagePageForm(brandId?, searchText?) {
  //   this.imagePageForm = this.formBuilder.group({
  //     brandId: [brandId ? brandId : this.selectedBrandObject.value],
  //     searchText: [this.searchText],
  //   });
  // }

  initForm() {
    this.pageForm = this.formBuilder.group({
      itemName: [this.itemDetails ? this.itemDetails.itemName : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      brandId: [this.itemDetails ? this.itemDetails.brandId : ''],
      brandName: [this.itemDetails ? this.itemDetails.brandName : ''],
      sellingPrice: [this.itemDetails && this.itemDetails.sellingPrice && this.itemDetails.sellingPrice > 0 ?
        this.itemDetails.sellingPrice.toFixed(2) : 0,
      [Validators.required, Validators.maxLength(9)]],
      selection: [this.itemDetails && this.itemDetails.selection ? this.itemDetails.selection : 'single'],
      minimumQuantity: [this.itemDetails && this.itemDetails.minQtyAllowed ? this.itemDetails.minQtyAllowed : '',
      [Validators.required, Validators.maxLength(4)]],
      maximumQuantity: [this.itemDetails && this.itemDetails.maxQtyAllowed ? this.itemDetails.maxQtyAllowed : '',
      [Validators.required, Validators.maxLength(4)]],
      image: [this.itemDetails && this.itemDetails.imageFileId ? this.itemDetails.imageFileId : '',
      [Validators.required]],
    });
    if (this.itemDetails && this.itemDetails.signUrl) {
      this.headerLabel = this.commonService.getLabel('label_food_extra_edit_item');
      this.localImgUrl = this.itemDetails.signUrl;
      // this.newMenuSelectedImageFileId = this.itemDetails.imageFileId;
    }
    this.onChangeSelection();
  }

  onCancelClick() {
    this.itemDetails = '';
    this.pageForm.reset();
    this.showAddClient = false;
    this.onCancelAddNew.emit();
  }

  onChangeSelection() {
    if (this.pageForm.controls.selection.value === 'single') {
      this.pageForm.controls.minimumQuantity.setValue('');
      this.pageForm.controls.maximumQuantity.setValue('');
      this.pageForm.controls.minimumQuantity.disable();
      this.pageForm.controls.maximumQuantity.disable();
      this.pageForm.controls.minimumQuantity.setValidators(null);
      this.pageForm.controls.maximumQuantity.setValidators(null);
      this.validMinimumQuantity = true;
      this.validMaximumQuantity = true;
    } if (this.pageForm.controls.selection.value === 'multiple') {
      this.pageForm.controls.minimumQuantity.enable();
      this.pageForm.controls.maximumQuantity.enable();
      this.pageForm.controls.minimumQuantity.setValidators([Validators.required, Validators.maxLength(4)]);
      this.pageForm.controls.maximumQuantity.setValidators([Validators.required, Validators.maxLength(4)]);
    }
    this.pageForm.controls.minimumQuantity.updateValueAndValidity();
    this.pageForm.controls.maximumQuantity.updateValueAndValidity();
    // this.checkAValid();
  }

  checkDisable() {
    return false;
  }

  checkForDisable() {
    return (this.itemDetails && this.itemDetails.id) ? true : false
  }

  onEditImageClick() {
    const isNew = this.itemDetails?false:true;
    this.getBrandList(this.loggedInUserId, isNew);
    this.menuImageList = this.franchiseService.foodExtraItemsImageList;
    this.localMenuImageList = this.franchiseService.foodExtraItemsImageList;
    this.searchText = '';
    this.showImageListPopUp = true;
    this.selectedImageIndex = this.pageForm.controls.image.value ? this.pageForm.controls.image.value : '';
  }

  setImagePageForm(brandId?, searchText?) {
    let selectedbrand;
    selectedbrand = this.brandNameOptions.filter((item)=>{
      return item.franchiseBrandId == brandId
    });
    this.menuBrand = selectedbrand[0].value ;
    // this.imagePageForm = this.formBuilder.group({
    //   brandId: [brandId ? selectedbrand[0].value : this.selectedBrandObject.value],
    //   searchText: [this.searchText],
    // });
  }

  onSearchImages(e) {
    // this.searchText = this.imagePageForm.controls.searchText.value;
    let filteredArray = [];
    if (this.searchText) {
      const trimSearch = this.searchText.trim().toLowerCase();
      filteredArray = this.menuImageList.filter(eachImage => (eachImage.originalFileName.trim().toLowerCase().indexOf(trimSearch) !== -1
        && eachImage.brandId == this.selectedBrandObject.value));
    } else {
      filteredArray = this.menuImageList;
    }
    this.localMenuImageList = filteredArray;
  }

  onCancelPopUp() {
    this.showImageListPopUp = false;
  }

  onSelectImage() {
    this.selectedImageObject = this.franchiseService.foodExtraItemsImageList.find(eachImage => eachImage.id === this.selectedImageIndex);
    if (this.selectedImageObject) {
      this.localImgUrl = this.selectedImageObject.signUrl;
      this.pageForm.controls.image.setValue(this.selectedImageObject.id);
      this.pageForm.controls.itemName.setValue(this.selectedImageObject.originalFileName);
      this.pageForm.controls.brandName.setValue(this.selectedBrandObject.label);
      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
        this.pageForm.controls.brandId.setValue(this.selectedBrandObject.value);
      } else {
        this.pageForm.controls.brandId.setValue(this.selectedBrandObject.value);
      }

      // if (this.itemDetails && this.itemDetails.id) {
      //   this.itemDetails.imageFileId = this.selectedImageObject.id;
      // } else {
      //   this.newMenuSelectedImageFileId = this.selectedImageObject.id;
      // }
      this.isImageSelected = true;
      this.showImageListPopUp = false;
    } else {
      this.uiService.showMessage(this.commonService.getLabel('msg_select_image'), AppSettings.MESSAGE_TYPE.ERROR);
    }
  }

  onSave() {
    this.submitted = true;
    if (this.checkAValid()) {
      if (this.itemDetails) { // update
        this.onUpdateClick();
      } else { // add
        const data = this.pageForm.getRawValue();
        if (this.pageForm.controls.image.value) {
          this.isImageSelected = true;
          const newItem = {
            foodExtraItemCategoryId: this.ID,
            itemName: data.itemName,
            selection: data.selection,
            sellingPrice: data.sellingPrice,
            minQtyAllowed: data.minimumQuantity,
            maxQtyAllowed: data.maximumQuantity,
            imageFileId: this.pageForm.controls.image.value,
            brandId: data.brandId,
          };
          this.confirmationService.confirm({
            message: this.commonService.getLabel('msg_food_extra_item_add_menu'),
            accept: () => {
              this.franchiseService.addFoodExtraItem(newItem).subscribe((result) => {
                this.reloadMenuList.emit();
                this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
                this.onCancelClick();
              }, error => {
              }
              );
            }
          });
        } else {
          this.isImageSelected = false;
          this.uiService.showMessage(this.commonService.getLabel('msg_select_image'), AppSettings.MESSAGE_TYPE.ERROR);
        }
      }
    } else {
      // console.log(this.pageForm.controls)
      this.errorBindingService.getFormValidationErrors(this.pageForm);
    }
  }

  onUpdateClick() {
    const data = this.pageForm.getRawValue();
    this.confirmationService.confirm({
      message: this.commonService.getLabel('msg_food_extra_item_edit_menu'),
      accept: () => {
        const updatedItem = {
          id: this.itemDetails.id,
          foodExtraItemCategoryId: this.ID,
          itemName: data.itemName,
          selection: data.selection,
          sellingPrice: data.sellingPrice,
          minQtyAllowed: data.minimumQuantity,
          maxQtyAllowed: data.maximumQuantity,
          imageFileId: this.pageForm.controls.image.value ?
            this.pageForm.controls.image.value : this.itemDetails.imageFileId,
          brandId: data.brandId,
        };
        this.franchiseService.updateFoodExtraItem(updatedItem).subscribe((result) => {
          this.reloadMenuList.emit();
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
        }, (error) => {
          console.log(error);
          this.reloadMenuList.emit();
        }
        );
      }
    });
  }

  checkMinMaxValueValid(fromInput:string) {
    // if ((Number(this.pageForm.controls.maximumQuantity.value) && Number(this.pageForm.controls.minimumQuantity.value))) {
    //   if (fromInput === 'minQuentity') {
    //     if (Number(this.pageForm.controls.minimumQuantity.value) >= Number(this.pageForm.controls.maximumQuantity.value)) {
    //       this.validMinimumQuantity = false;
    //     } else {
    //       this.validMinimumQuantity = true;
    //       this.validMaximumQuantity = true;
    //     }
    //   }

    //   if (fromInput === 'maxQuentity') {
    //     if (Number(this.pageForm.controls.maximumQuantity.value) <= Number(this.pageForm.controls.minimumQuantity.value)) {
    //       this.validMaximumQuantity = false;
    //     } else {
    //       this.validMaximumQuantity = true;
    //       this.validMinimumQuantity = true;
    //     }
    //   }
    // }
    const minimumQuantity = Number(this.pageForm.controls.minimumQuantity.value);
    const maximumQuantity = Number(this.pageForm.controls.maximumQuantity.value);
  
    if (!isNaN(minimumQuantity) && !isNaN(maximumQuantity)) {
      if (fromInput === 'minQuantity') {
        this.validMinimumQuantity = minimumQuantity < maximumQuantity;
      }
  
      if (fromInput === 'maxQuantity') {
        this.validMaximumQuantity = maximumQuantity > minimumQuantity;
      }
    }
    this.checkAValid();
  }

  checkAValid() {
     //** it may be useFull in future changes */
    // this.checkMinMaxValueWithinParentRange();    
    if (this.pageForm.valid && this.validMinimumQuantity && this.validMaximumQuantity) {
      // if (this.minQtyAllowed && this.maxQtyAllowed) {
      //   if (this.validMinimumQuantityInRange && this.validMaximumQuantityInRange) {
      //     this.valid = true;
      //   } else {
      //     this.valid = false;
      //   }
      // } else {
      //   this.valid = true;
      // }
      this.valid = true; // bypass validMinimumQuantityInRange;
      return true;
    } else {
      this.valid = false;
      return false;
    }
  }

  //** it may be useFull in future changes */

  // checkMinMaxValueWithinParentRange() {
  //   if ((this.minQtyAllowed && this.maxQtyAllowed && (this.itemDetails.selection !== 'single'))) {
  //     if (Number(this.pageForm.controls.minimumQuantity.value) && Number(this.pageForm.controls.maximumQuantity.value)) {
  //       if (Number(this.pageForm.controls.minimumQuantity.value) < Number(this.minQtyAllowed)) {
  //         this.validMinimumQuantityInRange = false;
  //       } else {
  //         this.validMinimumQuantityInRange = true;
  //       }
  //       if (Number(this.pageForm.controls.maximumQuantity.value) > Number(this.maxQtyAllowed)) {
  //         this.validMaximumQuantityInRange = false;
  //       } else {
  //         this.validMaximumQuantityInRange = true;
  //       }
  //     }

  //   }
  // }

  onChangeBrand(event) {
    this.selectedBrandObject = this.brandNameOptions.filter(eachBrand => eachBrand.value == event.value)[0];
    this.localMenuImageList = this.franchiseService.foodExtraItemsImageList.filter((item) => {
      return item.brandId == event.value;
    });
    this.searchText = '';
    this.menuImageList = this.localMenuImageList;
    this.menuItemsImageList = this.localMenuImageList;
  }

}
