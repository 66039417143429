import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import Chart from 'chart.js/auto';
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, AfterViewInit {


  @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
  barChart: any;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.barChartMethod1();
  }

  // barChartMethod() {
  //   this.barChart = new Chart(this.barCanvas?.nativeElement, {
  //     type: 'bar',
  //     data: {
  //       labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  //       datasets: [{
  //         label: 'Event Count - 2021-2022',
  //         // yAxisID: 'A',
  //         axis:'A',
  //         data: [60, 75, 70, 115, 130, 180, 200],
  //         backgroundColor: [
  //           'rgba(54, 162, 235, 0.2)',
  //         ],
  //         borderColor: [
  //           'rgba(54, 162, 235, 1)',
  //         ],
  //         borderWidth: 1,
  //         barPercentage: 1,
  //         categoryPercentage: 0.3,
  //         // barThickness: 20,
  //         maxBarThickness: 40,
  //        // barThickness: 40,
  //       }, {
  //         label: 'Event Count - 2020-2021',
  //         // yAxisID: 'B',
  //         axis:'B',
  //         // data: [67, 80, 50, 119, 135, 170, 180],

  //         backgroundColor: [
  //           'rgba(186, 185, 185, 0.2)',
  //         ],
  //         borderColor: [
  //           'rgba(186, 185, 185, 1)',
  //         ],
  //         borderWidth: 1,
  //         barThickness: 10,
  //         maxBarThickness: 40,
  //       }]
  //       // datasets: [
  //       //   {
  //       //     label: 'Event Count',

  //       //     data: [60, 75, 70, 115, 130, 180, 200],
  //       //     backgroundColor: [
  //       //       'rgba(54, 162, 235, 0.2)',
  //       //     ],
  //       //     borderColor: [
  //       //       'rgba(54, 162, 235, 1)',
  //       //     ],
  //       //     borderWidth: 1,
  //       //     // barPercentage: 0.5,
  //       //     barThickness: 40,
  //       //     maxBarThickness: 40,
  //       //   },
  //       // ],
  //     },
  //     options: {
  //       // indexAxis: 'y',
  //       scales: {
  //         x: {
  //           title: {
  //             color: 'red',
  //             display: true,
  //             text: 'Days of Week',
  //             font: {
  //               family: 'Montserrat',
  //               size: 15,
  //               weight: '500',
  //             },
  //             padding: 15,
  //           },
  //           grid: {
  //             display: false,
  //             offset: false,
  //             borderColor: 'black',
  //             borderWidth: 2
  //           },
  //           ticks: {
  //             font: {
  //               family: 'Montserrat',
  //             },
  //           }
  //         },
  //         y: {
  //           beginAtZero: true,
  //           title: {
  //             color: 'red',
  //             display: true,
  //             text: 'y axis',
  //             font: {
  //               family: 'Montserrat',
  //               size: 15,
  //               weight: '500',
  //             },
  //             padding: 15
  //           },
  //           grid: {
  //             borderColor: 'black',
  //             borderWidth: 2,
  //           }
  //         },
  //         A: {
  //           beginAtZero: true,
  //           type: 'linear',
  //           position: 'left',
  //           min: 0,
  //           max: 300,
  //           title: {
  //             color: 'red',
  //             display: true,
  //             text: 'A axis',
  //             font: {
  //               family: 'Montserrat',
  //               size: 15,
  //               weight: '500',
  //             },
  //             padding: 15
  //           },
  //           grid: {
  //             borderColor: 'black',
  //             borderWidth: 2,
  //           },
  //           ticks: {
  //             font: {
  //               family: 'Montserrat',
  //             },
  //           }
  //         },
  //         B: {
  //           beginAtZero: true,
  //           type: 'linear',
  //           position: 'right',
  //           min: 0,
  //           max: 400,
  //           title: {
  //             color: 'red',
  //             display: true,
  //             text: 'B axis',
  //             font: {
  //               family: 'Montserrat',
  //               size: 15,
  //               weight: '500',
  //             },
  //             padding: 15
  //           },
  //           ticks: {
  //             font: {
  //               family: 'Montserrat',
  //             },
  //           }
  //         }
  //       },
  //       plugins: {
  //         legend: {
  //           position: 'bottom',
  //           align: 'center',
  //           labels: {
  //             usePointStyle: true
  //           }
  //         },
  //         tooltip: {
  //           enabled: true,
  //         }
  //       },
  //       layout: {
  //         autoPadding: true
  //       },
  //       events: ['mousemove', 'mouseout', 'click'],
  //       interaction: {
  //         mode: 'nearest',
  //         axis: 'x'
  //       },
  //       onClick : function(e, dataset, chart) {
  //         if (e.type === 'click' && dataset[0] != undefined) {
  //           let index = dataset[0].index;
  //           document.getElementById('current-value')!.textContent = 'US$' + chart.data.datasets[0].data[index]!.toString();
  //           document.getElementById('compared-value')!.textContent = 'US$' + chart.data.datasets[1].data[index]!.toString();
  //           console.log(chart.data);
  //         }
  //       },
  //       onHover : function(e, dataset, chart) {
  //         if (e.type === 'mousemove' && dataset[0] != undefined) {
  //           let index = dataset[0].index;
  //           document.getElementById('current-value')!.textContent = 'US$' + chart.data.datasets[0].data[index]!.toString();
  //           document.getElementById('compared-value')!.textContent = 'US$' + chart.data.datasets[1].data[index]!.toString();
  //         }
  //       }
  //     },
  //   });
  // }


  barChartMethod1() {
    this.barChart = new Chart(this.barCanvas?.nativeElement, {
      type: 'bar',
      data: {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [{
          label: 'Event Count - 2021-2022',
          // yAxisID: 'A',
          axis: 'A',
          data: [60, 75, 70, 115, 130, 180, 200],
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 1,
        }, {
          label: 'Event Count - 2020-2021',
          // yAxisID: 'B',
          axis: 'B',
          data: [67, 80, 50, 119, 135, 170, 180],
          backgroundColor: [
            'rgba(186, 185, 185, 0.2)',
          ],
          borderColor: [
            'rgba(186, 185, 185, 1)',
          ],
        }]
      },
      options: {
        // indexAxis: 'y',
        scales: {
          // x: {
          //   title: {
          //     display: true,
          //     text: 'Days of Week',
          //   },
          // },
          // y: {
          //   beginAtZero: true,
          //   title: {
          //     display: true,
          //     text: 'y axis',
          //   },
          // },
          A: {
            beginAtZero: true,
            type: 'linear',
            position: 'left',
            min: 0,
            max: 200,
            title: {
              display: true,
              text: 'A axis',
            },
          },
          B: {
            beginAtZero: true,
            type: 'linear',
            position: 'right',
            min: 0,
            max: 400,
            title: {
              display: true,
              text: 'B axis',
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
          }
        },
      },
    });
  }


}

